<template>
	<div class="flex-1 bg-white pa-24 radius-20 mb-30">
		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
		</div>
		<!-- //고정해더 -->
		<div
			class="size-px-14 mt-30"
		>
			<div class="justify-space-between items-center">
				<div class="flex-1 flex-row gap-10">
					<button
						@click="onTab('in')"
						class="btn-inline radius-50 font-weight-500"
						:class="tab_type == 'in' ? 'btn-primary' : 'btn-light-gray02'"
						style="outline: unset; height: 40px; padding: 13px 30px;"
					>구독 멤버</button>

					<button
						@click="onTab('out')"
						class="btn-inline radius-50 font-weight-500"
						:class="tab_type == 'out' ? 'btn-primary' : 'btn-light-gray02'"
						style="outline: unset; height: 40px; padding: 13px 30px;"
					>구독 종료 멤버</button>
				</div>

				<div class="justify-space-between items-center position-relative">

					<select
						class="input-box pa-10-20 radius-20 cursor-pointer"
						style="width: 240px;"
					>
						<option>전체 구독 플랜</option>
					</select>
					<v-icon
						class="position-absolute" style="right: 10px; top: 8px"
					>mdi-chevron-down</v-icon>
				</div>
			</div>
			<div class="flex-1 input-box justify-space-between items-center mt-20 radius-20"
				style="padding: 8px 20px;"
			>
				<input v-model="item_member.nickname" placeholder="멤버 검색" class="flex-1" maxlength="20"/>
				<v-icon
					v-show="item_member.nickname"
					class="mr-10 size-px-20"
					@click="item_member.nickname = ''"
				>mdi-close-circle</v-icon>
				<button  @click="getData"><v-icon>mdi-magnify</v-icon></button>
			</div>

			<div class="mt-20">
				<div class="justify-space-between">
					<h5>구독자 멤버</h5>
					<span class="font-weight-600 color-mafia-blue size-px-16">{{ tot_subscrp_end_member_count | makeComma }}명</span>
				</div>
				<ul
					class="mt-20 flex-row-wrap justify-space-between gap-20"
					v-if="items_member.length > 0"
				>
					<li
						v-for="(item, index) in items_member"
						:key="'item_' + index"
						class="inline-block items-center mb-10"
						style="flex: 1 1 0; max-width: 401px"
						@click="toDetail(item)"
					>
						<div class="justify-space-between items-center">
							<div class="img-box-64 mr-10 radius-100"><img :src="item.profle_img_file_path" @error="$bus.$emit('onErrorProfileImage', $event)" class="object-cover"/></div>
							<div class="flex-1 flex-column gap-8">
								<div class="size-px-16 font-weight-500 line-height-1">{{ item.nickname }}</div>
								<div class="size-px-14 color-333 line-height-1">{{ item.subscrp_plan_name }}</div>
								<div class="size-px-13 color-888 line-height-1">{{ item.subscrp_enddate }}</div>
							</div>
							<div class="ml-10">
								<button
									class="btn-inline btn-primary-outline radius-20 size-px-12 font-weight-500"
									style="padding: 9px 14px;"
								>결제내역</button>
							</div>
						</div>
					</li>
				</ul>
				<Empty
					v-else
				></Empty>
			</div>
		</div>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
export default {
	name: 'SubscribeMember'
	, components: {Empty}
	, props: ['user']
	, data: function(){
		return {

			program: {
				name: '구독자 관리'
				, title: '구독자 관리'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, from: 'mafia049'
				, bg_contents: 'bg-gray01'
				, cartel: ''
				, is_side: true

			}
			, item_cartel: {

			}
			, item_member: {
				
			}
			, items_member: [
				{
					nickname: '닉네임'
					, subscrp_plan_name: '결제유형코드'
					, subscrp_enddate: '2023.05.31 종료'
					, profle_img_file_path: ''
					, subscrp_member_number: '12'
				}
				, {
					nickname: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200001'
					, profle_img_file_path: ''
					, subscrp_member_number: '12'
				}
				, {
					nickname: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200002'
					, profle_img_file_path: ''
					, subscrp_member_number: '12'
				}
			]
			, tab_type: 'in'
			, tot_subscrp_end_member_count: 12357
		}
	}
	, computed: {

	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getInMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_in_member
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, nickname: this.item_member.nickname
					}
					, type: true
				})

				if(result.success){
					this.tot_subscrp_end_member_count = result.data.tot_subscrp_member_count
					this.items_member = result.data.subscrp_member_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}


		, getOutMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_out_member
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, nickname: this.item_member.nickname
					}
					, type: true
				})

				if(result.success){
					this.tot_subscrp_end_member_count = result.data.tot_subscrp_end_member_count
					this.items_member = result.data.subscrp_end_member_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, goBack: function(){
			this.$bus.$emit('goBack')
		}
		, onTab: function(type){
			this.tab_type = type
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'SubscribeHistory', params: { idx: this.$route.params.idx, m_id: item.subscrp_member_number}})
		}
		, getData: function(){

			if(this.tab_type == 'in'){
				this.getInMember()
			}else{
				this.getOutMember()
			}
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		this.getData()
	}
	, watch: {
		tab_type: {
			handler: function(call){
				if(call == 'in'){
					this.getInMember()
				}else{
					this.getOutMember()
				}
			}
		}
	}

}
</script>